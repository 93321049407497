import { render, staticRenderFns } from "./distributionDig.vue?vue&type=template&id=958eb826&scoped=true"
import script from "./distributionDig.vue?vue&type=script&lang=js"
export * from "./distributionDig.vue?vue&type=script&lang=js"
import style0 from "./distributionDig.vue?vue&type=style&index=0&id=958eb826&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "958eb826",
  null
  
)

component.options.__file = "distributionDig.vue"
export default component.exports