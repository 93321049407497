<template>
    <div class="box">
        <el-dialog title="勘探人员" :visible.sync="drawer" class="selectorDialog">
            <div class="org-selected">
                <el-row>
                    <el-col :span="6" class="selectedUl pr10 bbox">
                        <span class="span"></span>
                        <p class="title">代理商公司名称</p>
                        <div class="selectedUl-box topmar">
                            <ul>
                                <li class="fixKist spanActive">
                                    <span class="floatLeft rightOrderBox">{{
                                        agentName
                                        }}</span>
                                </li>
                                <!-- <li class="fixKist" :class="select == i ? 'spanActive' : ''"
                                    v-for="(item, i)  in multipleSelection" :key="item.id">
                                    <span class="floatLeft rightOrderBox" @click='toSelect(i)'>{{
                                        item.teamName
                                    }}</span>
                                </li> -->
                            </ul>
                        </div>
                    </el-col>
                    <el-col :span="18">
                        <span class="span2"></span>
                        <p class="title">勘探人员列表</p>
                        <el-table class="fontSize-table" highlight-current-row @current-change="handleCurrentChange"
                            :row-style="{ height: '20px' }" :cell-style="{ padding: '0px' }"
                            header-row-class-name="table-head-row" :data="tableData" border height="calc(100vh - 296px)"
                            style="width: 100%; margin-top: 20px">
                            <el-table-column prop="name" label="勘探人员">
                            </el-table-column>
                            <el-table-column min-width="118px" label="部门名称" prop="deptName">
                            </el-table-column>
                            <el-table-column show-overflow-tooltip min-width="100px" prop="phone" label="手机号码">
                            </el-table-column>
                        </el-table>
                        <!-- 分页 -->
                        <!-- <el-pagination @size-change="handleSizeChangeAuthority"
                            @current-change="handleCurrentChangeAuthority" :current-page="queryInfoAuthority.currPage"
                            :page-size="queryInfoAuthority.pageSize" layout="total, prev, pager, next"
                            :total="totleAuthority" class="ml10" style="margin-bottom: 15px"></el-pagination> -->
                    </el-col>
                </el-row>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="confirmAuthority">确 认</el-button>
                <el-button @click="drawer = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            drawer: false,
            tableData: [],

            list: [
                { name: '张三' }
            ],
            queryInfoAuthority: {
                // 当前页数
                currPage: 1,
                // 每页显示多少数据
                pageSize: 100,
            },
            totleAuthority: 20,

            multipleSelection: [
            ],
            select: '0',
            agentName: JSON.parse(localStorage.getItem("userInfo")).userInfo.agentName,
            list: []
        };
    },
    created() {
    },
    methods: {
        toSelect(i) {
            console.log(i)
            this.select = i
        },
        init(list) {
            this.list = list
            this.drawer = true
            this.getAddress()
            this.getConstruction()

        },
        // 单选
        handleCurrentChange(val) {
            this.selectSingle = val
        },
        // 获取选择施工队
        async getConstruction() {
            let res = await this.$http.post('constructionTeam/list', {});
            this.multipleSelection = res.data.data.data
            console.log(this.multipleSelection, 'pppppppppp')
        },
        // 选中的数据
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        //删除
        delAuthority(id, i) {
            this.tableData.forEach((row) => {
                if (row.id == id) {
                    this.$refs.multipleTable.toggleRowSelection(row, false);
                }
            });
            // this.multipleSelection.splice(i, 1);
        },
        // 监听 pagesize改变的事件
        handleSizeChangeAuthority(newSize) {
            // console.log(newSize)
            this.queryInfoAuthority.pageSize = newSize;
            // this.getRoleInfo();
        },
        // 监听 页码值 改变事件
        handleCurrentChangeAuthority(newSize) {
            // console.log(newSize)
            this.queryInfoAuthority.currPage = newSize;
            // this.getRoleInfo();
        },

        // 获取列表数据
        async getAddress() {
            let data = {
                currPage: 1,
                pageSize: 10,
                condition: {
                    roleCode: "202406040471411546177",
                    agentCode: JSON.parse(localStorage.getItem("userInfo")).userInfo.agentCode,
                    keyword: ""
                }
            }
            let res = await this.$http.post('roleUserInfo/list', data);
            this.tableData = res.data.data.data
            console.log(this.tableData)
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        async confirmAuthority() {
            let allList = this.list.map(res => {
                res.reconnaissanceCode = this.selectSingle.userCode
                return res
            })
            let res = await this.$http.post('houseCustomer/allocation', allList)
            this.$message({
                message: '分配成功',
                type: 'success'
            });
            this.$router.push({ path: '/expoleManage' })
        },
    }
};
</script>
<style lang="less" scoped>
.box {
    width: 70%;

    .rightOrderBox {
        width: 80%;
    }

    .span {
        width: 5px;
        height: 22px;
        background-color: #02a7f0;
        position: absolute;
        top: -10px;
        left: -5px;
    }

    // .span2 {
    //     width: 5px;
    //     height: 22px;
    //     background-color: #02a7f0;
    //     position: absolute;
    //     top: -10px;
    //     left: 190px;
    //     margin-right: 7px;
    // }

    .title {
        position: absolute;
        top: -25px;
        font-size: 16px;
        font-weight: 800;
    }


    .topmar {
        margin-top: 20px;

        .fixKist {
            box-sizing: border-box;
            width: 100%;
            height: 40px;
            line-height: 16px;
            text-align: center;
            margin-bottom: 0px;
            background: #fff;
            padding: 14px 0 12px 27px;
        }

        .spanActive {
            background: rgba(47, 145, 223, 0.1);
        }
    }

    // /deep/ .el-form-item {
    //     margin-bottom: 16px !important;
    // }
    .selectorDialog .selectedUl {
        border-left: 0;
    }

    /deep/ .el-table .current-row>td {
        background-color: #F3992F !important;
        /* 更改为所需的颜色 */
    }

}
</style>
