<template>
    <div class="box">
        <el-dialog title="日志" :visible.sync="logVisible" center width="800px">
            <div>
                <div class="logInfoBox">
                    <el-row>
                        <el-col :span="12">
                            <div class="logInfoItem">
                                <div>客户名：</div>
                                <div>{{ logInfo.customerName }}</div>
                            </div>
                        </el-col :span="12">
                        <div class="logInfoItem">
                            <div>代理商编号：</div>
                            <div>{{ logInfo.agentName }}</div>
                        </div>
                    </el-row>
                </div>
                <div class="logHistory">日志历史</div>
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" max-height="400px" style="width: 100%" border>
                    <el-table-column prop="operateName" label="操作人" min-width="120" align="center">
                    </el-table-column>
                    <el-table-column prop="operateStatus" label="处理状态" min-width="120" align="center">
                        <template slot-scope="{row}">
                            {{ row.operateStatus == 1 ? '成功' : '失败' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="operateType" label="类型" min-width="120" align="center">
                    </el-table-column>
                    <el-table-column prop="operateDate" label="处理时间" min-width="140" align="center">
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            logVisible: false,
            tableData: [],
            logInfo: {},
            queryInfoAuthority: {
                // 当前页数
                currPage: 1,
                // 每页显示多少数据
                pageSize: 100,
            },
            totleAuthority: 20,

            multipleSelection: [
            ],
            select: '0',
        };
    },
    created() {
    },
    methods: {
        toSelect(i) {
            console.log(i)
            this.select = i
        },
        init(row) {
            this.logVisible = true
            this.logInfo = row
            this.getLogList()
            console.log(row, 'oooooooooooooooo')
        },
        // 获取选择施工队
        async getConstruction() {
            let res = await this.$http.post('constructionTeam/list', {});
            this.multipleSelection = res.data.data.data
        },
        // 选中的数据
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        //删除
        delAuthority(id, i) {
            this.tableData.forEach((row) => {
                if (row.id == id) {
                    this.$refs.multipleTable.toggleRowSelection(row, false);
                }
            });
            // this.multipleSelection.splice(i, 1);
        },
        // 监听 pagesize改变的事件
        handleSizeChangeAuthority(newSize) {
            // console.log(newSize)
            this.queryInfoAuthority.pageSize = newSize;
            // this.getRoleInfo();
        },
        // 监听 页码值 改变事件
        handleCurrentChangeAuthority(newSize) {
            // console.log(newSize)
            this.queryInfoAuthority.currPage = newSize;
            // this.getRoleInfo();
        },

        // 获取列表数据
        async getLogList() {
            let res = await this.$http.post('houseOperateLog/queryLogList', {});
            if (res.data.code == 200) {
                this.tableData = res.data.data
            }
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        confirmAuthority() {
            this.logVisible = false
            this.$emit('toInstallation', this.multipleSelection)
        },
    }
};
</script>
<style lang="less" scoped>
.box {
    width: 70%;

    .logInfoItem {
        display: flex;
    }
}
</style>
