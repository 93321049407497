<template>
  <div class="box">
    <el-dialog title="施工单位" :visible.sync="drawer" class="selectorDialog">
      <div class="org-selected">
        <el-row>
          <el-col :span="6" class="selectedUl pr10 bbox">
            <span class="span"></span>
            <p class="title">选择施工队</p>
            <div class="selectedUl-box topmar">
              <ul>
                <li
                  class="fixKist"
                  :class="select == i ? 'spanActive' : ''"
                  v-for="(item, i) in multipleSelection"
                  :key="item.id"
                >
                  <el-link
                    :underline="false"
                    class="floatLeft rightOrderBox"
                    @click="toSelect(i, item)"
                    >{{ item.teamName }}</el-link
                  >
                </li>
              </ul>
            </div>
          </el-col>
          <el-col :span="18">
            <span class="span2"></span>
            <p class="title">施工中订单：</p>
            <el-table
              class="fontSize-table"
              :row-style="{ height: '20px' }"
              :cell-style="{ padding: '0px' }"
              header-row-class-name="table-head-row"
              :data="tableData"
              v-loading="tableLoading"
              border
              height="calc(100vh - 280px)"
              style="width: 100%; margin-top: 20px"
            >
              <el-table-column
                prop="teamName"
                min-width="100px"
                label="施工队名称"
              >
              </el-table-column>
              <el-table-column
                min-width="118px"
                label="订单号"
                prop="orderNumber"
              >
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                width="100px"
                prop="customerName"
                label="客户名称"
              >
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                prop="customerPhone"
                min-width="150px"
                label="客户电话"
              >
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                width="100px"
                prop="customerName"
                label="施工状态"
              >
                <template v-slot="{ row }">
                  <span v-if="row.status == 0" style="color: #e6a23c"
                    >待施工</span
                  >
                  <span v-if="row.status == 1" style="color: #66b1ff"
                    >施工中</span
                  >
                  <span v-if="row.status == 2" style="color: #9da0a5"
                    >待审核</span
                  >
                  <span v-if="row.status == 3" style="color: #67c23a"
                    >审核完成</span
                  >
                  <span v-if="row.status == 4" style="color: #f56c6c"
                    >审核驳回</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                prop="constructionAddress"
                min-width="400px"
                label="施工地址"
              >
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                align="center"
                min-width="100px"
                prop="headame"
                label="负责人名称"
              >
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                align="center"
                max-width="100px"
                prop="componentNum"
                label="组件数量"
              >
              </el-table-column>
              <el-table-column
                align="center"
                max-width="80px"
                width="80px"
                prop="examineStatus"
                label="审核状态"
              >
                <template slot-scope="{ row }">
                  <el-tag type="success" v-if="row.examineStatus == 1"
                    >通过</el-tag
                  >
                  <el-tag type="danger" v-else-if="row.examineStatus == 0"
                    >驳回</el-tag
                  >
                </template>
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                align="center"
                prop="dispatcherName"
                label="派工人"
              >
              </el-table-column>
              <el-table-column
                min-width="150px"
                show-overflow-tooltip
                align="center"
                prop="dispatchDate"
                label="派工时间"
              >
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                align="center"
                prop="updateName"
                label="更新人"
              >
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                align="center"
                prop="updateDate"
                label="更新时间"
              >
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <!-- <el-pagination @size-change="handleSizeChangeAuthority" @current-change="handleCurrentChangeAuthority"
              :current-page="queryInfoAuthority.currPage" :page-size="queryInfoAuthority.pageSize"
              layout="total, prev, pager, next" :total="totleAuthority" class="ml10"
              style="margin-bottom: 15px"></el-pagination> -->
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmAuthority" :disabled="disabled">确 认</el-button>
        <el-button @click="drawer = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { status } from "nprogress";

export default {
  data() {
    return {
      drawer: false,
      tableLoading: true,
      tableData: [],

      list: [{ name: "张三" }],
      queryInfoAuthority: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 100,
      },
      totleAuthority: 20,

      multipleSelection: [],
      select: "0",
      parentRow: {},
      disabled:false,
    };
  },
  created() {},
  methods: {
    toSelect(i, item) {
      this.select = i;
      this.getAddress(item.teamCode);
    },
    init(row) {
      this.disabled = false
      this.select = "0";
      this.drawer = true;
      this.parentRow = row;
      this.getConstruction();
      if(row.isDispatch == 1){
        this.disabled = true
      }


    },
    // 获取选择施工队
    async getConstruction() {
      let res = await this.$http.post("constructionTeam/list", {});
      if (res.data.code == 200) {
        this.multipleSelection = res.data.data.data;
        this.getAddress(this.multipleSelection[0].teamCode);
      }
    },
    // 获取列表数据
    async getAddress(teamCode) {
      let res = await this.$http.post("/constructionCustomer/queryOrderList", {
        teamCode,
      });
      if (res.data.code == 200) {
        this.tableLoading = false;
      }
      this.tableData = res.data.data;
    },
    // 选中的数据
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 监听 pagesize改变的事件
    handleSizeChangeAuthority(newSize) {
      this.queryInfoAuthority.pageSize = newSize;
      // this.getRoleInfo();
    },
    // 监听 页码值 改变事件
    handleCurrentChangeAuthority(newSize) {
      this.queryInfoAuthority.currPage = newSize;
      // this.getRoleInfo();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    async confirmAuthority() {
      let data = {
        customerCode: this.parentRow.customerCode,
        orderNumber: this.parentRow.customerNumber,
        teamCode: this.multipleSelection[this.select].teamCode,
        dispatcherCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .agentCode,
        dispatcherName: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .agentName,
      };
      console.log(data);
      let res = await this.$http.post(
        "/constructionCustomer/constructionDispatch",
        data
      );
      if (res.data.code == 200) {
        this.tableLoading = false;
        this.$notify({
          title: "成功",
          message: "派单成功",
          type: "success",
        });
      }
      this.drawer = false;
      this.$emit("toInstallation", this.multipleSelection);
      this.$emit("getTable")
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  width: 70%;

  .rightOrderBox {
    width: 80%;
  }

  .span {
    width: 5px;
    height: 22px;
    background-color: #02a7f0;
    position: absolute;
    top: -10px;
    left: -5px;
  }

  .title {
    position: absolute;
    top: -25px;
    font-size: 16px;
    font-weight: 800;
  }

  .topmar {
    margin-top: 20px;

    .fixKist {
      box-sizing: border-box;
      width: 100%;
      height: 40px;
      line-height: 16px;
      text-align: center;
      margin-bottom: 0px;
      background: #fff;
      padding: 14px 0 12px 27px;
    }

    .spanActive {
      background: rgba(47, 145, 223, 0.1);
    }
  }

  .selectorDialog .selectedUl {
    border-left: 0;
  }
}
</style>
