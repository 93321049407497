export const add = (explorateImgType, explorateImgUrl, array) => {
  let obj = {
    explorateImgType,
    explorateImgUrl
  };
 return  array = [...array, obj];
};


export const remove = (item, array) => {
  console.log(item,array.length);
  return array.splice(array.indexOf(item), 1);
}

